import React, { Fragment, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Modal from "react-modal";
import YouTube from 'react-youtube';
import { useTranslation } from "react-i18next";
 

//Gifs

import bkgif from "../assets/gifs/fullbk.gif";
import jdugif from "../assets/gifs/jdu.gif";
import klagif from "../assets/gifs/Klasico.gif";
import nutrigif from "../assets/gifs/nutriben.gif";
import nutrigiftwo from "../assets/gifs/nutriben2.gif";
import sportgif from "../assets/gifs/sportline.gif";
import lifegif from "../assets/gifs/lifestyle.gif";
import Arboleadas from "../assets/gifs/Arboleadas.gif"
import Ani from "../assets/gifs/fullani.gif"


function Projectsview() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modaltwoIsOpen, setModalwolIsOpen] = useState(false);
  const [modalthreeIsOpen, setModalhreelIsOpen] = useState(false);
  const [modalfourIsOpen, setModalfourlIsOpen] = useState(false);

  const [modalfivelIsOpen, setModalfivelIsOpen] = useState(false);
  const [modalsixIsOpen, setModalsixIsOpen] = useState(false);
  const [modalsevenIsOpen, setModalsevenIsOpen] = useState(false);
  const [modaleightIsOpen, setModaleightIsOpen] = useState(false);
  const [modalsnineIsOpen, setModalnineIsOpen] = useState(false);


  const [t] = useTranslation("global");

  
   
   //Aos
   useEffect(() => {
    Aos.init({ duration:1300 });
},[]);
  
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const opts = {
    height: '500',
    width: '355',
    playerVars: {
      // https://www.youtube.com/watch?v=4F8UQ6Vxu_Q
      autoplay: 1,
    },
  };
  return (
    <Fragment>
      <section className="Principal-container-projects">
        <article className="Principal-container-article">
          <div  className="Video-one-saul" onClick={() => setModalhreelIsOpen(true)}>
            <img data-aos="fade-up" src={jdugif} id="BK" alt="prewiew projects"></img>
          </div>
          <div className="Video-two-sportline" onClick={() => setModalsevenIsOpen(true)}>
            <img data-aos="fade-up"  src={sportgif} id="BK" alt="prewiew projects"></img>
          </div>
          <div className="Video-three-anita" onClick={() => setModalwolIsOpen(true)}>
            <img data-aos="fade-up" src={Ani} id="BK" alt="prewiew projects"></img>
          </div>
          <div   className="Video-four-bk" onClick={() => setModalIsOpen(true)}>
            {" "}
            <img   src={bkgif}  alt="prewiew projects"></img>
          </div>
          <div className="Video-five-klasico"  onClick={() => setModalfivelIsOpen(true)}>
            <img data-aos="fade-up"  src={klagif} id="BK" alt="prewiew projects"></img>
          </div>
          <div className="Video-six-arboleada" onClick={() => setModalsixIsOpen(true)}>
            <img data-aos="fade-up"  src={Arboleadas} id="BK" alt="prewiew projects"></img>
          </div>
          <div className="Video-seven-nutriben-red"  onClick={() => setModaleightIsOpen(true)}>
            <img data-aos="fade-up"   src={nutrigiftwo} id="BK" alt="prewiew projects"></img>
          </div>
          <div className="Video-eight-arboleada-kids"  onClick={() => setModalsixIsOpen(true)}>
            <img data-aos="fade-up"  src={lifegif} id="BK" alt="prewiew projects"></img>
          </div>
          <div  className="Video-eight-nutriben-blue"  onClick={() => setModalnineIsOpen(true)}>
            <img data-aos="fade-up"  src={nutrigif} id="BK" alt="prewiew projects"></img>
          </div>

          {/* BK Modal */}
          <Modal isOpen={modalIsOpen} className="Modal">
            <div className="Youtebe-video">
            <YouTube videoId="ERKLCg-Eea4" opts={opts} onReady />

            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/* Anita Modal */}

          <Modal isOpen={modaltwoIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="PxIrpK9t6LU" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalwolIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>

          </Modal>

          {/* Jdu Modal */}
          <Modal isOpen={modalthreeIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="gwat_4AB1Fo" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalhreelIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/* Nutri one*/}
          <Modal isOpen={modalfourIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube  className="Youtubeid" videoId="" opts={opts} onReady />

            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalfourlIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/*NUtri two */}

          <Modal isOpen={modalfourIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalfourlIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/* Klasico */}

          <Modal isOpen={modalfivelIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="517r-7cEMX0" opts={opts} onReady />

            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalfivelIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/*  */}

          <Modal isOpen={modalsixIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube  className="Youtubeid" videoId="hHdeO4XT2js" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalsixIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/*  Modal Sport*/}

          <Modal isOpen={modalsevenIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube  className="Youtubeid" videoId="sXgKBbIdvtA" opts={opts} onReady />

            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalsevenIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/* Red Nutri Modal  */}

          <Modal isOpen={modaleightIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="kVTlGnuOuSo" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModaleightIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>

          {/* Nutri Blue Modal */}

          <Modal scroll="no" isOpen={modalsnineIsOpen} className="Modal">
            <div className="Youtebe-video" >
            <YouTube className="Youtubeid" videoId="H_32fzCqxko" opts={opts} onReady />
            </div>
            <button
              className="Btn-closemodal"
              onClick={() => setModalnineIsOpen(false)}
            >
              {t("Portfolio.More")}
            </button>
          </Modal>
        </article>
      </section>
    </Fragment>
  );
}

export default Projectsview;
