import React, { Fragment } from "react";
import Carousel from "react-elastic-carousel";

import gifone from "../assets/Filters/1.MOV"
import giftwo from "../assets/Filters/2.MOV"
import gifthree from "../assets/Filters/3.MOV"
import giffour from "../assets/Filters/Julia.mp4"

import { useTranslation } from "react-i18next";


function Filters() {

  const [t] = useTranslation("global");


  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
  ];


  return (
    <Fragment>
      <div className="Clients-container Root-filters-container">
        <div className="Title-filters-section">
          <p className="Title-filters-principal" >{t("Filters.Title")} <p className="Aumented"> {t("Filters.Orangeword")}</p>         </p>
          <p className="Filters-text">{t("Filters.Text")}
            <br />
          </p>
        </div>
      </div>

      <section className="Filter-carousel-container">


        <article style={{ color: "red" }}>

        <video  className="Clients-carousel Filters-card Filters-center side-filters" src={giffour}  autoPlay={true} muted loop> </video>

        </article>

        <Carousel
          className="carousel-filters"
          disableArrowsOnEnd={false}
          enableAutoPlay={false}
          pagination={false}
          loop={true}
          showArrows={true}
          itemsToScroll={1}
          autoPlaySpeed={200}
          enableMouseSwipe={true}
          breakPoints={breakPoints}
        >
          <div>
          <video  className="Clients-carousel Filters-card Filters-center" src={gifone}  autoPlay={true} muted loop> </video>
          
          </div>

          <div>
          <video  className="Clients-carousel Filters-card Filters-center" src={giftwo}  autoPlay={true} muted loop> </video>
          
          </div>


          <div>
          <video  className="Clients-carousel Filters-card Filters-center" src={giffour}  autoPlay={true} muted loop> </video>
          
          </div>

         


        </Carousel>

        <article style={{ color: "red" }}>

        <video  className="Clients-carousel Filters-card Filters-center side-filters" src={gifthree}  autoPlay={true} muted loop> </video>

        </article>
      </section>


    </Fragment >
  );
}

export default Filters;
