import React,{Fragment} from 'react'
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import Video from "./VideoBack"
import { useTranslation } from "react-i18next";

export default function ScrollAnimation() {


  const [t] = useTranslation("global");


const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());

    return (
        <Fragment>
             <ScrollContainer>
      <ScrollPage page={0}>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
        <Video></Video>
        </Animator>
      </ScrollPage>
      <ScrollPage page={1}>
        <Animator animation={ZoomInScrollOut}>
          <span className="Color-scroll" style={{ fontSize: "3em" }}> {t("Scroll.Textone")}</span>
        </Animator>
      </ScrollPage>
      <ScrollPage page={2}>
        <Animator animation={FadeUp}>
          <span  className="Color-scroll" style={{ fontSize: "3em" }}>{t("Scroll.Texttwo")}</span>
        </Animator>
      </ScrollPage>
      <ScrollPage page={3}>
        <div style={{marginTop:"200px"}}>
          <span style={{ fontSize: "3em" }}>
            <Animator  className="Color-scroll" animation={MoveIn(-100, 500)}>{t("Scroll.Texthree")}  </Animator>
            <Animator className="Color-scroll" animation={MoveIn(1000, 0)}>{t("Scroll.Textfour")}</Animator>
            <Animator className="Color-scroll" animation={MoveOut(1000, 0)}>{t("Scroll.Textfive")}</Animator>
            <Animator className="Color-scroll"animation={MoveOut(-1000, 0)}>{t("Scroll.Textsix")}</Animator>
          </span>
        </div>
      </ScrollPage>
      <ScrollPage page={4}>
        <Animator animation={batch(Fade(), Sticky())}>
          <span style={{ fontSize: "3em" }}>{t("Scroll.Textseven")}  </span>
          <span style={{ fontSize: "3em" }}>
          {t("Scroll.Texteight")}          
          </span>
        </Animator>
      </ScrollPage>
    </ScrollContainer>
        </Fragment>
    )
}
