import React,{Fragment} from "react"
import Quick from "../assets/quick.webm"


export default function VideoBack(){

  
    return(
        <Fragment>
            <section className="VideoContainer">
        <video  src={Quick} id="videoBG" autoPlay={true} muted loop>
       </video>
       </section>
        </Fragment>
    );
}