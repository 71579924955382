import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll, Link } from "react-scroll";

const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: #000;
  position:;
  z-index:99;
  position:absolute;

  opacity:30;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;



const Menu = styled.ul`
  list-style: none;
  display: flex;

  li:nth-child(2) {
    margin: 0px 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.li``;



const NavIcon = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #fff;
  transition: width 0.4s ease-in-out;

  :nth-child(2) {
    width: ${props => (props.open ? "40%" : "70%")};
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: ${props => (props.open ? "91vh" : 0)};
  width: 100vw;
  background: #1c2022;
  transition: height 0.4s ease-in-out;

  @media (min-width: 769px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`  position:;

  list-style: none;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 50px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;

const Header = () => {

    //Toggle
  const [toggle, toggleNav] = useState(false);

   //Translations
   const [t, i18n] = useTranslation("global");

 

 //Scroll Down Effect

 const toggleHome = () => {
   scroll.scrollToTop();
 };
  
 
  return (
    <>
      <Nav style={{position:"fixed", width:"100%", opacity:"0.9", backgroundColor:"#000", zIndex:"9999"}}>
        <h2 onClick={toggleHome} style={{cursor:"pointer"}} className="Nav-Logo" style={{color:"white"}}>GGS Medios</h2>
        <Menu>
          <Item>
          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="Projects"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
          >
            {t("Header.Projects")}
          </Link>
          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="AboutUs"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
          >
            {t("Header.About")}
          </Link>
          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="Services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
          >
            {t("Header.Services")}
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            to="Contact"
            className="Links-navbar"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
          >
            {" "}
            {t("Header.Contact")}
          </Link>
          </Item>
          <Item>
           
            <button
            className="LenguageButton"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </button>
          <button
            className="LenguageButton"
            onClick={() => i18n.changeLanguage("es")}
          >
            ES
          </button>
          </Item>
        </Menu>
        <NavIcon onClick={() => toggleNav(!toggle)}>
          <Line open={toggle} />
          <Line open={toggle} />
          <Line open={toggle} />
        </NavIcon>
      </Nav>
      <Overlay style={{zIndex:"200",marginTop:"55px", backgroundColor:"black",position:"fixed", opacity:"0.9"}} open={toggle}>
        <OverlayMenu style={{zIndex:"200",textAlign:"center"}} open={toggle} >
          <Item>
          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="Projects"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            onClick={() => toggleNav(!toggle)}
            offset={-80}
            activeClass="active"
          >
            {t("Header.Projects")}
          </Link>
          </Item>
          <Item>

          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="AboutUs"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
            onClick={() => toggleNav(!toggle)}
          >
            {t("Header.About")}
          </Link>
            
          </Item>
          <Item>
          
          <Link
            style={{ cursor: "pointer" }}
            to="Contact"
            className="Links-navbar"
            smooth={true}
            onClick={() => toggleNav(!toggle)}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
          >
            {" "}
            {t("Header.Contact")}
          </Link> </Item>
          <Item> 
          <Link
            className="Links-navbar"
            style={{ cursor: "pointer" }}
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            onClick={() => toggleNav(!toggle)}
            offset={-80}
            activeClass="active"
          >
            {t("Header.Services")}
          </Link>
          </Item> 
          <Item>
          
            
            <button
            className="LenguageButton"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </button>
          <button
            className="LenguageButton"
            onClick={() => i18n.changeLanguage("es")}
          >
            ES
          </button>
          </Item>
        </OverlayMenu>
      </Overlay>
    </>
  );
};

export default Header;

         