import React,{useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Cases = () => {

  const [t] = useTranslation("global");

  
   //Aos
   useEffect(() => {
    Aos.init({ duration: 1300 });
  }, []);

 
 
  
  return (
      <div id="Services" className="container-fluid">
          
        <div data-aos="fade-up" className="row">

            <div className="case">
              <div  className="case-details">
                <span className="Services-span">{t("Services.Titleone")}</span>
                <h3 className="h3-services">{t("Services.Titleone")}</h3>
              </div>
              <div   className="case-image ">
              </div>
            </div>

            <div className="case">
              <div className=" case-details">
                <span className="Services-span"> {t("Services.Titletwo")}</span>
                <h3 className="h3-services">{t("Services.Titletwo")}</h3>
              </div>
              <div   className="case-image case-five">
              </div>
            </div>

            <div className="case">
              <div className="case-details">
                <span className="Services-span">{t("Services.Titlethree")}</span>
                <h3 className="h3-services">{t("Services.Titlethree")}</h3>
              </div>
              <div   className="case-image case-three">
              </div>
            </div>

            <div className="case">
              <div className="case-details">
                <span className="Services-span">{t("Services.Titlefour")}</span>
                <h3 className="h3-services">{t("Services.Titlefour")}</h3>
              </div>
              <div   className="case-image case-four">
              </div>
            </div>


            <div className="case">
              <div className="case-details">
                <span className="Services-span">{t("Services.Titlefive")}</span>
                <h3 className="h3-services">{t("Services.Titlefive")}</h3>
              </div>
              <div   className="case-image case-two">
              </div>
            </div>


            <div className="case">
              <div className="case-details">
                <span className="Services-span">{t("Services.Titlesix")}</span>
                <h3 className="h3-services"> {t("Services.Titlesix")}</h3>
              </div>
              <div   className="case-image case-six">
              </div>
            </div>


            

          

        </div>

      </div>
      
      
  );
};

export default Cases;
