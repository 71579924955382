import React, { Fragment,useEffect } from "react";
import Letter from "../assets/contact.svg";
import Typewriter from "typewriter-effect";
import emailjs from "emailjs-com";
import Aos from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";


export default function Contact() {

  const [t] = useTranslation("global");

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_f1f1p34', 'template_8dn9j3j', e.target, 'user_8hzeSR2Da4DqmACGLgCEs')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

     //Aos
   useEffect(() => {
    Aos.init({ duration:1300 });
},[]);
  return (
    <Fragment>
      <section id="Contact" className="Contact-container-section">
        <h1  data-aos="fade-up" className="Contact-title">
          <Typewriter
            options={{
              strings: [t("Contact.Contact-title")],
              autoStart: true,
              loop: true,
              delay: 80,
            }}
          />
        </h1>
      </section>
      <div className="Typewriter-contact-container">
        <img  data-aos="fade-up" src={Letter} alt="More text"></img>
        <div className="Contact-Form-info-container">
        <h2 className="Form-h2"> {t("Contact.Contact-info")}</h2>
        <div className="container">
            <form onSubmit={sendEmail} className="Form-container" >
                    <div >
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="Input-form" placeholder={t("Contact.Labelone")} name="name"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="email" className="Input-form" placeholder={t("Contact.Labeltwo")} name="email"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="text" className="Input-form" placeholder={t("Contact.Labelthree")} name="subject"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <textarea className="Text-tarea" id="" cols="30" rows="8" placeholder={t("Contact.Labelfour")} name="message"></textarea>
                        </div>
                        <div className="Contactbtn-container">
                            <input type="submit" className="btn btn-info" value={t("Contact.Button")}></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </Fragment>
  );
}
