import React, { Fragment } from "react";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

function ProjectsTitle() {

  const [t] = useTranslation("global");



  return (
    <Fragment>
        <section id="Projects" className="Project-landing-container">
        <p className="Portfolio">{t("Portfolio.Portfolio")}</p>
      <h1 className="Project-title">
        <Typewriter
          options={{
            strings: [t("Portfolio.Textone"), t("Portfolio.Texttwo")],
            autoStart: true,
            loop: true,
            delay: 80,
          }}
        />
      </h1>
      <p className="Project-subtitle-text">{t("Portfolio.Textthree")}</p>
      </section>
    </Fragment>
  );
}

export default ProjectsTitle;
