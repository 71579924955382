import React, { Fragment, useEffect } from "react";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

function AboutUs() {

  //Aos
  useEffect(() => {
    Aos.init({ duration: 1300 });
  }, []);

  const [t] = useTranslation("global");

  
  return (
    <Fragment>

      <section id="AboutUs" className="About-section-container" >
      
        <section data-aos="fade-up" className="Info-square-aboutsection">
          <div className="Title-aboutus-container">
            <h1 className="Aboutus-principal-title ">

              <Typewriter
                options={{
                  strings: [t("About.Texttwo"), t("About.Textthree"), t("About.Textone")],
                  autoStart: true,
                  loop: true,

                }}
              />
              {t("About.Textnine")}
            </h1>
          </div>
          <div className="Articules-about-container">
            <article className="Accountant-articule-about">
              <div className="Accountant-fact factone">
                5+ <span className="About-Us-span">{t("About.Years")}</span>
              </div>
              <div className="Accountant-fact">
                100+ <span className="About-Us-span">{t("About.Projects")}</span>
              </div>
              <div className="Accountant-fact factone">
                20+ <span className="About-Us-span">{t("About.Clients")}</span>
              </div>
              <div className="Accountant-fact">
                10 <span className="About-Us-span">{t("About.Profesionals")}</span>
              </div>
              <article className="How-container-aboutus">
                <h2 >
                  <Typewriter
                    options={{
                      strings: [t("About.Textfour")],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
                <span>
                  <ul>
                    <li>{t("About.Textfour")}</li>
                    <li>{t("About.Textfive")}</li>
                    <li>{t("About.Textsix")}</li>
                  </ul>
                  <span className="Orange-square-span">
                    {t("About.Texteight")}                  </span>
                </span>
              </article>
            </article>
          </div>
        </section>
      </section>
    </Fragment>
  );
}

export default AboutUs;
