import React, { Fragment } from "react";
import whatsapps from "../assets/icons/whatsapp.svg";
import pin from "../assets/icons/pinw.svg";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import orange from "../assets/icons/Whitelogo.png";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll, Link } from "react-scroll";

export default function Footer() {
    //Scroll Down Effect

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const [t] = useTranslation("global");

    return (
        <Fragment>
            <section className="Principal-footer-container">
                <section className="Section-footer">
                    <div className="Left-footer footer-container">
                        <h2 className="Footer-title ggmediosfooter">GGS MEDIOS</h2>
                        <a href="https://api.whatsapp.com/send?phone=50766766805" className="Li-footer"   target="blank">
                            <span className="socialmedia">
                                <img className="pin" src={whatsapps} alt="more " />
                            </span>
                            <p className="Socialmedia">WhatsApp</p>
                        </a>
                        <a href="https://www.instagram.com/ggsmedios/"   target="blank" className="Li-footer">
                            <span className="socialmedia">
                                <img className="pin" src={instagram} alt="more " />
                            </span>
                            <p className="Socialmedia">Instagram</p>
                        </a>
                        <a   target="blank" href="https://www.linkedin.com/company/ggsmedios/" className="Li-footer linkedin">
                            <span>
                                <img className="pin" src={linkedin} alt="more "  />
                            </span>
                            <p className="Socialmedia">Linkedin</p>
                        </a>
                    </div>

                    <div className="Mid-footer footer-container">
                        <img
                            alt="logo"
                            style={{ cursor: "pointer" }}
                            onClick={toggleHome}
                            src={orange}
                            className="Logo-footer"
                            alt="more "
                        ></img>
                        <a
                            target="blank"
                            href="https://www.google.com/maps/d/viewer?ie=UTF8&oe=UTF8&msa=0&mid=1JCw_4ujE6FBPJx6whNJAZvDsBjE&ll=9.002936000000007%2C-79.532754&z=17"
                        >
                            <span className="Direction">
                                <img className="pin" src={pin} alt="more "></img> Century Tower, Mezzanine,
                                {t("Footer.Office")} M15
                            </span>
                        </a>
                    </div>

                    <div className="Right-footer footer-container">
                        <h2 className="Footer-title">{t("Footer.Meet")}</h2>
                        <Link
                            className="Links-navbar-footer"
                            style={{ cursor: "pointer" }}
                            to="Projects"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            activeClass="active"
                        >
                            {t("Header.Projects")}
                        </Link>
                        <Link
                            className="Links-navbar-footer"
                            style={{ cursor: "pointer" }}
                            to="AboutUs"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            activeClass="active"
                        >
                            {t("Header.About")}
                        </Link>

                        <Link
                            className="Links-navbar-footer"
                            style={{ cursor: "pointer" }}
                            to="services"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            activeClass="active"
                        >
                            {t("Header.Services")}
                        </Link>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="Contact"
                            className="Links-navbar-footer"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            activeClass="active"
                        >
                            {t("Header.Contact")}
                        </Link>
                    </div>
                </section>
            </section>
            <span className="Copy-footer">
                <span className="Copy-text">{t("Footer.Right")}</span>
            </span>
        </Fragment>
    );
}
