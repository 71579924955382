import React, { Fragment } from "react"
import './App.css';
import ProjectTitle from "./components/ProjectsTitle"
import Clients from "./components/ClientsCaro"
import Projectsview from "./components/ProjectsView";
import Cases from "./components/cases"
import Clock from "./components/Clock"
import Contact from "./components/Contact"
import ScrollAnimation from "./components/ScrollAnimation";
import AboutUs from "./components/AboutUs"
import Footer from "./components/Footer"
import Header from "./components/Header";
import Filters from "./components/Filters";

function App() {

  return (
    <Fragment className="App">
    <Header/>
    <ScrollAnimation/>
    <AboutUs/>
    <Cases/>
    <ProjectTitle/>
    <Projectsview/>
    <Filters/>
    <Clients/>
    <Contact/>
    <Clock/>
    <Footer/>
      
    </Fragment>
  );
}

export default App;
