import React, { Fragment } from "react";
import Carousel  from "react-elastic-carousel";
import Robles from "../assets/Marcas/robleswhite.png";
import Imbox from "../assets/Marcas/imbox.png";
import Nutriben from "../assets/Marcas/nutriben.png";
import Virsa from "../assets/Marcas/virsawhite.png";
import Simple from "../assets/Marcas/simplebwhite.png";
import Capital from "../assets/Marcas/logoriginalwhite.png";
import Blanco from "../assets/Marcas/BLANCO-01.png";
import Decore from "../assets/Marcas/decorewhite.png";
import { useTranslation } from "react-i18next";



function ClientsCaro() {


  const [t] = useTranslation("global");


  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];
 

  return (
    <Fragment>
      <div  className="Clients-container">
        <h1>{t("Brands.Title")}</h1>
        <Carousel 
       
          disableArrowsOnEnd={false}
          enableAutoPlay={false}
          pagination={false}
          loop={true}
          showArrows={true}
          itemsToScroll={3}
          autoPlaySpeed={200}
          enableMouseSwipe={true}
          breakPoints={breakPoints}
        >
          <div>
            
            <img
              className="Clients-carousel"
              src={Robles}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel logotext-image"
              src={Simple}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel"
              src={Virsa}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel  logotext-image nutriben "
              src={Nutriben}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel logotext-image"
              src={Imbox}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel logotext-image nutriben"
              src={Capital}
              alt="flamingo"
            />
          </div>
          <div>
            
            <img
              className="Clients-carousel logotext-image"
              src={Blanco}
              alt="flamingo"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              className="Clients-carousel logotext-image decore"
              src={Decore}
              alt="flamingo"
            />{" "}
          </div>
        </Carousel>
      </div>
    </Fragment>
  );
}

export default ClientsCaro;
