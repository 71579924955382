import React, {useState,Fragment} from "react";
import clockimg from "../assets/logoblack.png"
import { useTranslation } from "react-i18next";
import { animateScroll as scroll, Link } from "react-scroll";


const Clock = () => {
    
    let time = new Date().toLocaleTimeString();

    // useState
    const [ctime,setCtime] = useState(time);

    //Translations
    const [t] = useTranslation("global");

    const UpdateTime = () =>
    {
        time = new Date().toLocaleTimeString();
        setCtime(time);
    };

    setInterval(UpdateTime,1000);
    return(
        <Fragment>
            <section className="Clock-section-container">
                <div className="Container-clock-img">
                    <img src={clockimg} alt="clock" ></img>
                </div>
                <div className="Clock-info-container">
                    <h1 className="Clock-h1">{ctime}</h1>
                    <h2 className="Perfect-time">{t("Clock.Title")}</h2>
                    <Link
                      style={{ cursor: "pointer" }}
                      to="Contact"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      activeClass="active"
                    
                    className="Btn-clock">{t("Clock.Contact")}</Link>
                    </div>
            </section>
        </Fragment>
    )


};


export default Clock